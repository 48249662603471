<template>
  <Box
    class="card"
    :solid="true"
    :headerBorder="false"
    :title="title"
    :type="type"
    :style="getStyle()"
    v-bind="$attrs"
  >
    <template #title v-if="$slots.header">
      <slot name="header"></slot>
    </template>
    <template #before-body>
      <slot name="before-body"></slot>
    </template>
    <slot></slot>
    <template #after-body>
      <slot name="after-body"></slot>
    </template>
    <template #footer v-if="'footer' in $slots">
      <slot name="footer"><span></span></slot>
    </template>
  </Box>
</template>

<script>
import Box from "./box";

export default {
  name: "Card",
  components: { Box },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      required: false,
      default: "default",
      validator(value) {
        return (
          [
            "default",
            "primary",
            "info",
            "warning",
            "success",
            "danger"
          ].indexOf(value) >= 0
        );
      }
    }
  },
  methods: {
    getStyle() {
      return "footer" in this.$slots ? {} : { "--footer-height": "0px" };
    }
  }
};
</script>

<style lang="scss" scoped>
.card::v-deep {
  --header-height: 41px;
  --footer-height: 50px;
  --footer-height-fixed: 50px;
  overflow: hidden;

  & > .box-header .box-title {
    font-size: 1.6rem;

    @media (max-width: 767px) {
      font-size: 1.5rem;
    }
  }

  & > .box-body {
    height: calc(100% - var(--footer-height) - var(--header-height));
    overflow: hidden auto;
  }

  & > .box-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: var(--footer-height-fixed);
  }

  .footer-enter-active,
  .footer-leave-active {
    transition: bottom 300ms;
  }

  .footer-enter,
  .footer-leave-to {
    bottom: calc(var(--footer-height-fixed) * -1);
  }
}
</style>
