var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"users-card"},[_c('ListCard',_vm._b({key:_vm.titleText,attrs:{"title":_vm.titleText,"list":_vm.filteredUsers,"selected":_vm.selected,"itemTitle":"email","itemText":_vm.showPrivileges ? _vm.getUserPrivileges : null,"searchFields":"email","singleSelection":_vm.singleSelection,"activeClass":_vm.singleSelection ? 'selected-user' : '',"textIcon":_vm.group ? 'fa fa-tag' : 'fa fa-users',"emptyListText":_vm.emptyListText ||
        (_vm.group ? _vm.$t('no_users_in_group') : _vm.$t('no_users_in_process_area')),"sort":true,"disabled":_vm.isUserDisabled,"disabledText":_vm.$t('you_cant_edit_contract_owner'),"actions":_vm.allowRemove
        ? [
            {
              type: 'danger',
              title: _vm.$tc('remove_user_from_hierarchy', 1, {
                hierarchy: _vm.$tc(_vm.group ? 'group' : 'process_area').toLowerCase()
              }),
              disabled: _vm.isUserDisabled,
              trigger: (item) => _vm.confirmRemove([item]),
              icon: 'trash',
              swipe: true
            }
          ]
        : []},on:{"select":_vm.select,"filter":function($event){_vm.searchFilteredUsers = $event}},scopedSlots:_vm._u([{key:"bulk-actions",fn:function({ items }){return [_c('transition',{attrs:{"name":"slide"}},[(_vm.selectedUsers.length > 1 && _vm.allowRemove)?_c('BaseButton',{attrs:{"type":"danger","small":"","title":_vm.$tc('remove_user_from_hierarchy', 2, {
              hierarchy: _vm.group ? _vm.$tc('group') : _vm.$tc('process_area')
            })},on:{"click":function($event){$event.stopPropagation();return _vm.confirmRemove(items)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()],1),(_vm.showAddHierarchyEntity)?_c('BaseButton',{staticClass:"add-hierarchy-entity-btn",attrs:{"type":"info","small":"","title":_vm.$t(`titles.add_${_vm.group ? 'process_areas' : 'groups'}_to_users`),"data-toggle":"tooltip","data-delay-show":"1000"},on:{"click":_vm.hierarchyEntityShortcut}},[_c('i',{staticClass:"fa fa-plus corner-icon"}),_c('i',{class:`fa fa-${_vm.group ? 'tag' : 'users'}`})]):_vm._e()]}},(_vm.$slots.footer)?{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}:null],null,true)},'ListCard',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }