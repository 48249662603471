class SwipeHandler {
  swipedir;
  startX;
  startY;
  distX;
  distY;

  threshold; // required min distance traveled to be considered swipe
  maxThreshold; // optional max distance traveled to be considered swipe
  restraint; // maximum distance allowed at the same time in perpendicular direction

  startTime;
  allowedTime; // maximum time allowed to travel that distance
  elapsedTime;

  onSwipe;
  onSwipeMove;

  touchSurface; // target element

  constructor(
    touchSurface,
    {
      threshold = 150,
      maxThreshold = 9999,
      restraint = 100,
      allowedTime = 300,
      onSwipe = () => {},
      onSwipeMove = () => {}
    }
  ) {
    this.threshold = threshold;
    this.maxThreshold = maxThreshold;
    this.restraint = restraint;
    this.allowedTime = allowedTime;

    this.onSwipe = onSwipe;
    this.onSwipeMove = onSwipeMove;

    this.touchSurface = touchSurface;

    this.setEventListeners(true);
  }

  onTouchStart(e) {
    let touchobj = e.changedTouches[0];
    this.swipedir = "none";
    this.startX = touchobj.pageX;
    this.startY = touchobj.pageY;
    this.startTime = new Date().getTime(); // record time when finger first makes contact with surface
  }

  onTouchMove(e) {
    e.preventDefault(); // prevent scrolling when inside touch surface
    let touchobj = e.changedTouches[0];
    // get current distance for both axis
    let currDistX = touchobj.pageX - this.startX;
    let currDistY = touchobj.pageY - this.startY;
    // call swipe move handler with distance travelled
    // in both axis (clamping at the restraint)
    this.onSwipeMove({
      target: this.touchSurface,
      distanceX:
        Math.abs(currDistX) <= this.maxThreshold
          ? currDistX
          : currDistX < 0
          ? -this.maxThreshold
          : this.maxThreshold,
      distanceY:
        Math.abs(currDistY) <= this.maxThreshold
          ? currDistY
          : currDistY < 0
          ? -this.maxThreshold
          : this.maxThreshold
    });
  }

  onTouchEnd(e) {
    let touchobj = e.changedTouches[0];
    this.distX = touchobj.pageX - this.startX; // get horizontal dist traveled by finger while in contact with surface
    this.distY = touchobj.pageY - this.startY; // get vertical dist traveled by finger while in contact with surface
    this.elapsedTime = new Date().getTime() - this.startTime; // get time elapsed
    let threshold = this.computeValue(this.threshold);

    if (this.elapsedTime <= this.allowedTime || this.allowedTime == null) {
      // first condition for swipe met
      if (
        Math.abs(this.distX) >= threshold &&
        Math.abs(this.distY) <= this.restraint
      ) {
        // 2nd condition for horizontal swipe met
        this.swipedir = this.distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
      } else if (
        Math.abs(this.distY) >= threshold &&
        Math.abs(this.distX) <= this.restraint
      ) {
        // 2nd condition for vertical swipe met
        this.swipedir = this.distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
      }
    }
    this.onSwipe({ target: this.touchSurface, direction: this.swipedir });
  }

  setEventListeners(on = true) {
    let action = on ? "add" : "remove";
    this.touchSurface[`${action}EventListener`](
      "touchstart",
      this.onTouchStart.bind(this),
      false
    );
    this.touchSurface[`${action}EventListener`](
      "touchmove",
      this.onTouchMove.bind(this),
      false
    );
    this.touchSurface[`${action}EventListener`](
      "touchend",
      this.onTouchEnd.bind(this),
      false
    );
  }

  computeValue(prop, ref = "width") {
    return typeof prop == "string" && prop.includes("%")
      ? (parseFloat(prop) * this.touchSurface.getBoundingClientRect()[ref]) /
          100
      : prop;
  }
}

let swipeHandlers = new Map();

export default {
  bind(el, { value }) {
    swipeHandlers.set(el, new SwipeHandler(el, value));
  },
  unbind(el) {
    swipeHandlers.get(el).setEventListeners(false);
    swipeHandlers.delete(el);
  }
};
