<template>
  <component
    :is="tag"
    v-on="$listeners"
    :class="['list-group-item', { [activeClass]: active }]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "ListGroupItem",
  inject: ["linked"],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    activeClass: {
      type: String,
      default: "active"
    }
  },
  computed: {
    tag() {
      return this.linked ? "a" : "li";
    }
  }
};
</script>

<style scoped></style>
