<template>
  <div class="hierarchy" role="group" :aria-label="$t('hierarchy')">
    <h4 class="hidden-xs">{{ $t("hierarchy") }}</h4>
    <div class="btn-group">
      <BaseButton
        :type="value == 'group' ? 'primary' : 'default'"
        :active="value == 'group'"
        small
        @click="$emit('input', 'group')"
      >
        {{ $tc("permission_group") }}
      </BaseButton>
      <BaseButton
        :type="value == 'process-area' ? 'primary' : 'default'"
        :active="value == 'process-area'"
        small
        @click="$emit('input', 'process-area')"
      >
        {{ $tc("process_area") }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/buttons/base-button";

export default {
  name: "HierarchyToggle",
  components: {
    BaseButton
  },
  props: {
    value: {
      type: String,
      default: "group"
    }
  }
};
</script>

<style scoped>
.hierarchy {
  display: flex;
  align-items: center;
  gap: 10px;
}

h4 {
  font-size: 15px;
  color: #555;
}
</style>
