export default {
  methods: {
    localeSort(list, reduce = (v) => v, asc = true) {
      const collator = new Intl.Collator(this.$i18n.locale, {
        sensitivity: "base",
        numeric: true
      });
      return list.sort((a, b) =>
        collator.compare(reduce(asc ? a : b), reduce(asc ? b : a))
      );
    }
  }
};
